const React = require('react');
const config = require('./auth_config.json');
const history = require('./src/helpers/history');
const { Auth0Provider } = require('./src/hooks/useAuth0');

const onRedirectCallback = appState => {
	history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

// eslint-disable-next-line
exports.wrapRootElement = ({ element }) => {
	return (
		<Auth0Provider
			domain={config.domain}
			client_id={config.clientId}
			redirect_uri={window.location.origin}
			onRedirectCallback={onRedirectCallback}
		>
			{element}
		</Auth0Provider>
	);
};
